import React from "react";
import { useNavigate } from "react-router-dom";

const Account = ({ imgurl, acname, nav }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white h-4/6 flex flex-col items-center justify-center w-3/12 cursor-pointer rounded-[15px]"
      onClick={() => navigate(nav)}
    >
      <div className=" h-4/6 w-4/6 flex items-center justify-center">
        <img src={imgurl} alt="loan Account" className="h-10 w-10" />
      </div>
      <div className="text-[14px] font-[600] font-sans">{acname} </div>
    </div>
  );
};

export default Account;
