import React, { createContext, useState, useEffect } from "react";
import { getUserData } from "./GlobalContext";

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      const accId = localStorage.getItem("acc_id")
      const response = await getUserData(accId)
      const data = response.data;
      if (response.status === "success") {
        setUser(data);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    getUser(); 
    console.log(user);
  }, []); 

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
