export const getTransactions = async (data) => {
  const formData = new URLSearchParams();
  formData.append("account_no", data.account_no);

  try {
    const response = await fetch(
      "https://nagnathfinance.in/API/acc_transaction.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching user data:", error.message);
  }
};
