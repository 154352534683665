export const getUserData = async (accountId) => {
  try {
    const apiUrl = "https://nagnathfinance.in/API/get_user_by_id.php";
    const params = new URLSearchParams({
      account_id: accountId,
      // account_no: 'ABC',
    });

    const url = `${apiUrl}?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching user data:", error.message);
  }
};
