import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Account from "../../componants/Home/Account";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Loader from "../../assets/Loader.svg";
import { getEMI } from "./context";

const Home = ({ setnavactive }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [pendingEMI, setPendingEMI] = useState();

  const getData = async () => {
    const res = await getEMI(user.Account_No);
    const EMI = res.data;
    if (res) {
      const pendingInstallments = [];

      if (EMI) {
        EMI.forEach((installment) => {
          if (installment.installment_status === "pending") {
            pendingInstallments.push(installment);
          }
        });
        setPendingEMI(pendingInstallments[0]);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // getData();

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 30 },
    },
  };

  const Welcome = () => {
    return (
      <motion.div className="flex text-white h-1/6" variants={itemVariants}>
        <div
          className=" flex items-start py-4 justify-center h-full w-1/6 cursor-pointer"
          onClick={() => setnavactive(true)}
        >
          <svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.83333C0 1.3471 0.171205 0.880788 0.475952 0.536971C0.780698 0.193155 1.19402 0 1.625 0H24.375C24.806 0 25.2193 0.193155 25.524 0.536971C25.8288 0.880788 26 1.3471 26 1.83333C26 2.31956 25.8288 2.78588 25.524 3.1297C25.2193 3.47351 24.806 3.66667 24.375 3.66667H1.625C1.19402 3.66667 0.780698 3.47351 0.475952 3.1297C0.171205 2.78588 0 2.31956 0 1.83333ZM0 11C0 10.5138 0.171205 10.0475 0.475952 9.70364C0.780698 9.35982 1.19402 9.16667 1.625 9.16667H24.375C24.806 9.16667 25.2193 9.35982 25.524 9.70364C25.8288 10.0475 26 10.5138 26 11C26 11.4862 25.8288 11.9525 25.524 12.2964C25.2193 12.6402 24.806 12.8333 24.375 12.8333H1.625C1.19402 12.8333 0.780698 12.6402 0.475952 12.2964C0.171205 11.9525 0 11.4862 0 11ZM0 20.1667C0 19.6804 0.171205 19.2141 0.475952 18.8703C0.780698 18.5265 1.19402 18.3333 1.625 18.3333H24.375C24.806 18.3333 25.2193 18.5265 25.524 18.8703C25.8288 19.2141 26 19.6804 26 20.1667C26 20.6529 25.8288 21.1192 25.524 21.463C25.2193 21.8068 24.806 22 24.375 22H1.625C1.19402 22 0.780698 21.8068 0.475952 21.463C0.171205 21.1192 0 20.6529 0 20.1667Z"
              fill="white"
            />
          </svg>
        </div>
        <div className=" justify-center px-4  flex flex-col  h-full w-6/12 ">
          <div className="font-sans font-[700] text-[24px]">Welcome</div>
          <div className="text-[16px] font-[600]">{user.Name}</div>
        </div>
        <div
          className="  h-full w-4/12 flex justify-end items-center px-4 cursor-pointer"
          onClick={() => navigate("/Profile")}
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="25" fill="#EDF2F4" />
            <path
              d="M15 32.5C15 31.1739 15.5268 29.9021 16.4645 28.9645C17.4021 28.0268 18.6739 27.5 20 27.5H30C31.3261 27.5 32.5979 28.0268 33.5355 28.9645C34.4732 29.9021 35 31.1739 35 32.5C35 33.163 34.7366 33.7989 34.2678 34.2678C33.7989 34.7366 33.163 35 32.5 35H17.5C16.837 35 16.2011 34.7366 15.7322 34.2678C15.2634 33.7989 15 33.163 15 32.5Z"
              stroke="#2B2D42"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M25 22.5C27.0711 22.5 28.75 20.8211 28.75 18.75C28.75 16.6789 27.0711 15 25 15C22.9289 15 21.25 16.6789 21.25 18.75C21.25 20.8211 22.9289 22.5 25 22.5Z"
              stroke="#2B2D42"
              strokeWidth="2"
            />
          </svg>
        </div>
      </motion.div>
    );
  };

  const AccountInformation = () => {
    return (
      <motion.div
        className="flex text-black flex-col mx-6  h-3/6 mt-4 bg-white rounded-[20px]"
        variants={itemVariants}
      >
        <div className="flex flex-col justify-center  items-center h-4/6 ">
          <div className=" font-[600] text-[14px] font-sans w-full h-2/6 flex justify-center items-center">
            <span>Account Balance</span>
            <span className="ml-1">
              <IoIosArrowDown />
            </span>
          </div>
          <div className=" flex-col flex w-full h-4/6">
            <div className="font-sans font-[700] text-[32px] w-full h-3/6 flex items-center justify-center">
              ₹ {user.Account_balance}
            </div>
            <div
              className="font-sans font-[600] text-[14px] w-full h-3/6 flex justify-center items-center cursor-pointer"
              onClick={() => navigate("/Pigmi")}
            >
              ₹ 10,000 in Pigmi
              <IoIosArrowForward className="ml-2 flex justify-center items-center" />
            </div>
          </div>
          {/* <div className=" w-full h-2/6">Account Balanace</div> */}
        </div>
        {pendingEMI ? (
          <div className="flex justify-center items-center h-2/6 ">
            <div className="bg-[#EDF2F4] rounded-[10px] h-4/6 w-full items-center px-2 mx-4 flex justify-between">
              <div className="font-sans font-[600] text-[14px] ml-2">
                Next due on {pendingEMI.installment_date}
              </div>
              <div className="font-[600] text-[#10CE0C] text-[14px] mr-2">
                ₹{pendingEMI.installment_amount}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-2/6 ">
            <div className="bg-[#EDF2F4] rounded-[10px] h-4/6 w-full items-center px-2 mx-4 flex justify-center">
              <p className="flex justify-center items-center">
                No active loans
              </p>
            </div>
          </div>
        )}
      </motion.div>
    );
  };

  const Accounts = () => {
    return (
      <motion.div
        className="justify-around items-center flex border-white h-2/6"
        variants={itemVariants}
      >
        <Account acname="Loan" imgurl="/loan.jpeg" nav="/loan" />
        <Account acname="FD" imgurl="/fd.jpeg" nav="/FixedDeposite" />
        <Account acname="Pigmi" imgurl="/pigmi.jpeg" nav="/pigmi" />
      </motion.div>
    );
  };

  const Transaction = () => {
    const navigate = useNavigate();
    return (
      <motion.div
        className="mt-4 cursor-pointer rounded-[10px] bg-[#EDF2F4] text-black p-2 mx-4 flex justify-between"
        variants={itemVariants}
        onClick={() => navigate("/Transactions")}
      >
        <div className="font-sans font-[600] text-[14px] ml-2 py-1">
          RECENT TRANSACTION
        </div>
        <div className="mr-2 flex justify-center items-center py-1">
          <svg
            width="8"
            height="16"
            viewBox="0 0 8 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 15L7 8L1 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </motion.div>
    );
  };

  return (
    <motion.div
      className="bg-[#2B2D42] mx-auto lg:w-[360px] h-screen w-screen flex flex-col"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {!user ? (
        <img
          className="flex justify-center items-center"
          src={Loader}
          alt="Loading..."
        />
      ) : (
        <>
          <div className="h-3/6 w-full">
            <Welcome />
            <AccountInformation />
            <Accounts />
          </div>
          <div className="h-3/6 border rounded-t-[20px] mt-2 bg-white w-full flex flex-col">
            <Transaction />
          </div>
        </>
      )}
    </motion.div>
  );
};

export default Home;
