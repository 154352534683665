import React from "react";

const PigmiAccount = ({ amt }) => {
  return (
    <div className="bg-white  py-2 flex flex-col items-center text-[#FFFFFF] h-[188px] mx-4 my-2 rounded-[25px]">
      <div className="mt-4">
        <img
          className="h-[68px] w-[77px]"
          src="/pigmi_gray.png"
          alt="pigmi logo"
        />
      </div>
      <div className="  h-2/6 w-full flex items-center justify-center font-sans text-[20px] font-[600]  text-black">
        Total Amount
      </div>
      <div className="  h-2/6 w-full flex items-start justify-center font-sans text-[20px] font-[800] text-black">
        Rs. {amt}
      </div>
    </div>
  );
};

export default PigmiAccount;
