import React from "react";

const Infodisplay = ({ col_name, col_val }) => {
  return (
    <div className="flex flex-col">
      <div className="text-[15px] font-[600] font-sans">{col_name}</div>
      <div className="font-sans h-[45px] flex items-center text-black text-[14px] font-[400] my-1 bg-[#EDF2F4] p-2 rounded-[15px]">
        <div className="ml-2">{col_val}</div>
      </div>
    </div>
  );
};

export default Infodisplay;
