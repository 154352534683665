import React from "react";
import { motion } from "framer-motion"; // Import motion
import { IoMdHome } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { GiReceiveMoney } from "react-icons/gi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { FaCoins } from "react-icons/fa";

// Updated Option component to be a motion component
const Option = ({ logo, option, nav }) => {
  const navigate = useNavigate();
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 20 },
    },
  };

  // Define hover animation
  const hoverAnimation = {
    scale: 1.1, // Slightly scale up the Option component on hover
    transition: { type: "spring", stiffness: 20 },
  };

  return (
    <motion.div
      className="flex cursor-pointer justify-start hover:ml-4 items-center space-x-4"
      onClick={() => navigate(nav)}
      variants={itemVariants}
      whileHover={hoverAnimation} // Apply hover animation
    >
      <div className="text-xl">{logo}</div>
      <div>{option}</div>
    </motion.div>
  );
};
const Sidebar = ({ navactive, setnavactive }) => {
  // Define your animation variants including staggerChildren
  const sidebarVariants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 30,
        staggerChildren: 0.3, // Add staggerChildren for sequential animation
      },
    },
    closed: {
      x: "-100%",
      opacity: 0,
      transition: { type: "spring", stiffness: 30 },
    },
  };

  // Container variants for children
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }, // Stagger the animation of children
    },
  };

  return (
    <div className="lg:flex lg:w-screen lg:justify-center ">
      <motion.div
        className="text-black lg:border fixed rounded-r-3xl lg:w-[360px] h-screen mx-auto z-500"
        initial="closed"
        animate={navactive ? "open" : "closed"}
        variants={sidebarVariants}
      >
        <div className="absolute text-black rounded-r-3xl bg-white lg:w-[260px] h-screen w-screen mx-auto z-1000">
          <div className="text-black h-[70px] flex justify-between items-center px-4">
            <div className="font-bold text-xl text-blue-800">
              Nagnath Finance
            </div>
            <div
              className="text-xl cursor-pointer"
              onClick={() => setnavactive(!navactive)}
            >
              <IoMdClose />
            </div>
          </div>
          <motion.div
            className="h-full flex flex-col space-y-4 py-4 px-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Option logo={<IoMdHome />} option="Home" nav="/home" />
            <Option logo={<CgProfile />} option="Profile" nav="/Profile" />
            <Option logo={<GiReceiveMoney />} option="Loan" nav="/loan" />
            <Option
              logo={<FaMoneyBillTrendUp />}
              option="Fixed Deposite"
              nav="/FixedDeposite"
            />
            <Option logo={<FaCoins />} option="Pigmi" nav="/pigmi" />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Sidebar;
