import React from "react";

const Infodate = ({date,inst_amt}) => {
  return (
    <div>
      <div className="font-sans  flex-col  h-[80px] flex items-start text-black justify-center text-[14px] font-[400] my-1 bg-[#EDF2F4] p-2 rounded-[15px]">
        <div className="flex items-center h-3/6 w-full">
          <div className="text-[15px] font-[600] font-sans ml-2">Date :</div>{" "}
          <div className="mx-2 text-[15px] font-[400] font-sans">
            {date}
          </div>
        </div>
        <div className="flex items-center h-3/6 w-full">
          <div className="text-[15px] font-[600] font-sans ml-2">Installment Amount :</div>{" "}
          <div className="mx-2 text-[15px] font-[400] font-sans">
            {inst_amt}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infodate;
