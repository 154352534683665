import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import PageTitle from "../../componants/UI/PageTitle";
import InforBox from "../../componants/UI/InforBox";
import TransInfo from "../../componants/UI/TransInfo";
import UserContext from "../../context/UserContext";
import { getTransactions } from "./context";

const Transactions = () => {
  const { user } = useContext(UserContext)
  const [transactions, setTransactions] = useState([])

  const getData = async () => {
    const res = await getTransactions(user.Account_No)
    console.log(res);
  }

  useEffect(() => {
    getData()
  }, [])

  // Animation variants for the container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.6 },
    },
  };

  // Animation variants for the elements inside InforBox
  const elementVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5, duration: 0.6 },
    },
  };
  return (
    <motion.div
      className="bg-[#2B2D42] mx-auto lg:w-[360px] h-screen w-screen flex flex-col"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <PageTitle title="Transactions" nav="/home" />
      <motion.div
        className="w-full overflow-auto py-4 pb-4 h-full rounded-t-[25px] bg-[#EDF2F4]"
        variants={elementVariants}
      >
        <InforBox title="Transaction">
          <TransInfo date="20 Feb 2024" amount="10,000" status="Credited" />
          <TransInfo date="20 Feb 2024" amount="10,000" status="Debited" />
          <TransInfo date="20 Feb 2024" amount="10,000" status="Credited" />
        </InforBox>
      </motion.div>
    </motion.div>
  );
};

export default Transactions;
