import "./App.css";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from "./context/UserContext";

function App() {
  return (
   <UserProvider>
     <Router>
      <AppRoutes />
    </Router>
   </UserProvider>
  );
}

export default App;
