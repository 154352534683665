import React from "react";

const TransInfo = ({ date, amount, status }) => {
  return (
    <div>
      <div className="font-sans  flex-col  h-[110px] flex items-start text-black justify-center text-[14px] font-[400] my-1 bg-[#EDF2F4] p-2 rounded-[15px]">
        <div className="flex items-center h-2/6 w-full">
          <div className="text-[15px] font-[600] font-sans ml-2">Date :</div>{" "}
          <div className="mx-2 text-[15px] font-[400] font-sans">{date}</div>
        </div>
        <div className="flex items-center h-2/6 w-full">
          <div className="text-[15px] font-[600] font-sans ml-2">Amount :</div>{" "}
          <div className="mx-2 text-[15px] font-[400] font-sans">Rs. {amount}</div>
        </div>
        <div className="flex items-center h-2/6 w-full">
          <div className="text-[15px] font-[600] font-sans ml-2">Status :</div>{" "}
          <div
            className={`mx-2 font-sans font-[400] text-[15px] ${
              status === "Credited" ? "text-[#10CE0C]" : "text-[#F40000]"
            }`}
          >
            {status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransInfo;
