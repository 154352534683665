import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { login } from "./context";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [error, seterror] = useState({
    error_active: false,
    error_field: "account_number",
    error_msg: "Account number is required",
  });
  const [login_Details, setlogin_Details] = useState({
    account_no: "",
    password: "",
  });
  const onInputChangeHandler = (event) => {
    const { name, value } = event.target;
    setlogin_Details((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onSignInHandler = async () => {
    const res = await login(login_Details);
    const data = res.data;
    setUser(data);
    console.log(data);
    if (data && data.Account_Id) {
      localStorage.setItem("acc_id", data.Account_Id)
      localStorage.setItem("acc_no", data.Account_No)
      navigate(`/`);
    }
  };
  return (
    <div className="h-screen mx-auto lg:w-[360px] w-screen">
      <svg
        width="full"
        height="169"
        viewBox="0 0 360 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="180" cy="-50" rx="245" ry="219" fill="#2B2D42" />
      </svg>

      <div className="h-[100px] justify-center items-center mt-4  w-full flex flex-col">
        <div className="font-sans text-[24px] font-[700] text-[#030637]">
          Welcome to
        </div>
        <div className="font-sans text-[24px] font-[700] text-[#030637]">
          Nagnath Finance
        </div>
        <div className="font-sans font-[600] text-[20px]">
          Login to your account
        </div>
      </div>
      <div className="h-[280px] rounded-2xl mx-8 mt-8 p-6 pb-8 border-black bg-[#EDF2F4]">
        <div className="  h-full flex flex-col  ">
          <div className="h-3/6 w-full  flex flex-col ">
            <div className=" h-2/6 w-full font-sans text-[15px] font-[600] text-black">
              Account Number
            </div>
            <div className=" h-4/6 w-full">
              <input
                type="text"
                className="w-full h-4/6 rounded-2xl pl-4 text-[14px] font-[400]"
                placeholder="Your Account Number"
                onChange={onInputChangeHandler}
                required
                name="account_no"
              ></input>
              <div className=" text-red-500">
                {error.error_active &&
                  error.error_field === "account_number" &&
                  error.error_msg}
              </div>
            </div>
          </div>
          <div className="h-3/6 w-full  flex flex-col ">
            <div className=" h-2/6 w-full font-sans text-[15px] font-[600] text-black">
              Password
            </div>
            <div className=" h-4/6 w-full">
              <input
                type="text"
                className="w-full h-4/6 rounded-2xl pl-4 text-[14px] font-[400]"
                placeholder="Your Password"
                onChange={onInputChangeHandler}
                name="password"
                required
              ></input>
              <div className=" text-red-500">
                {error.error_active &&
                  error.error_field === "password" &&
                  error.error_msg}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={onSignInHandler}
        className="bg-[#2B2D42] mx-10 my-4 text-white font-sans flex justify-center item-center py-2 rounded-2xl font-[600] text-[18px]"
      >
        Sign In
      </div>
    </div>
  );
};

export default Login;
