import { Outlet, useRoutes, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Profile from "./pages/Profile/Profile";
import Loan from "./pages/Loan/Loan";
import FixedDeposite from "./pages/FixedDeposite/FixedDeposite";
import Pigmi from "./pages/Pigmi/Pigmi";
import { useContext, useState } from "react";
import Transactions from "./pages/Transaction/Transactions";
import Sidebar from "./componants/UI/Sidebar";
import UserContext from "./context/UserContext";

function AppRoutes() {
  const { user } = useContext(UserContext);
  // console.log(user);
  const [navactive, setnavactive] = useState(false);
  const accountId = localStorage.getItem("acc_id")
  // Define a private route function
  const PrivateRoute = ({ element, ...props }) => {
    return user ? (
      <Outlet {...props} />
    ) : (
      <Navigate to="/login" replace />
    );
  };

  // Define your routes
  let routes = useRoutes([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/",
          element: (
            <>
              {navactive ? (
                <Sidebar navactive={navactive} setnavactive={setnavactive} />
              ) : <Home setnavactive={setnavactive}/>}
            </>
          ),
        },
        { path: "profile", element: <Profile /> },
        { path: "loan", element: <Loan /> },
        { path: "fixeddeposite", element: <FixedDeposite /> },
        { path: "pigmi", element: <Pigmi /> },
        { path: "Transactions", element: <Transactions /> },
      ],
    },
    { path: "login", element: <Login /> }, // Login route
    { path: "*", element: <Navigate to="/" replace /> }, // Default route for 404
  ]);

  return routes;
}

export default AppRoutes;
