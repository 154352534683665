import React from "react";
import { motion } from "framer-motion";

const InforBox = ({ children, title }) => {
  // Define animation variants for the container
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1, // Delay between each child animation
      },
    },
  };

  // Define animation variants for children
  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <div className="mx-4">
        <div className="text-[18px] font-sans font-[600] my-2">{title}</div>
        <motion.div
          className="bg-white flex-col rounded-[25px] flex space-y-2 px-4 py-4"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {React.Children.map(children, (child, index) => (
            <motion.div key={index} variants={childVariants}>
              {child}
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default InforBox;
