import React, { useContext } from "react";
import InforBox from "../../componants/UI/InforBox";
import Infodisplay from "../../componants/UI/Infodisplay";
import PageTitle from "../../componants/UI/PageTitle";
import { motion } from "framer-motion";
import UserContext from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("acc_id");
    localStorage.removeItem("acc_no");
    navigate("/login");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="bg-[#2B2D42] mx-auto lg:w-[360px] h-screen w-screen flex flex-col"
    >
      <PageTitle title="My Account" nav="/home" />
      <div className="w-full overflow-auto pb-4 h-full rounded-t-[25px] bg-[#EDF2F4] animate-slideUp">
        <div className="flex justify-center items-center p-4">
          <svg
            width="120"
            height="120"
            viewBox="0 0 120 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="60" cy="60" r="60" fill="#2B2D42" />
            <path
              d="M33 81.875C33 78.0957 34.4487 74.4711 37.0273 71.7987C39.6059 69.1263 43.1033 67.625 46.75 67.625H74.25C77.8967 67.625 81.3941 69.1263 83.9727 71.7987C86.5513 74.4711 88 78.0957 88 81.875C88 83.7647 87.2757 85.5769 85.9864 86.9131C84.697 88.2493 82.9484 89 81.125 89H39.875C38.0516 89 36.303 88.2493 35.0136 86.9131C33.7243 85.5769 33 83.7647 33 81.875Z"
              stroke="white"
              strokeWidth="5"
              strokeLinejoin="round"
            />
            <path
              d="M60.5 53.375C66.1954 53.375 70.8125 48.59 70.8125 42.6875C70.8125 36.785 66.1954 32 60.5 32C54.8046 32 50.1875 36.785 50.1875 42.6875C50.1875 48.59 54.8046 53.375 60.5 53.375Z"
              stroke="white"
              strokeWidth="5"
            />
          </svg>
        </div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <InforBox title="Your information">
            <Infodisplay col_name="Account Number" col_val={user.Account_No} />
            <Infodisplay col_name="Adhar Number" col_val={user.Aadhar_No} />
            <Infodisplay col_name="PAN Number" col_val={user.Pan_no} />
            <Infodisplay col_name="Address" col_val={user.Address} />
            <Infodisplay col_name="Mobile Number" col_val={user.Mobile} />
          </InforBox>
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <InforBox title="Finance Office Contact">
            <Infodisplay col_name="Contact Number 1" col_val="+91 9284345454" />
            <Infodisplay col_name="Contact Number 2" col_val="+91 9284345454" />
          </InforBox>
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <InforBox title="Logout">
            <p
              className="font-extrabold flex justify-center items-center text-red-600 cursor-pointer"
              onClick={() => handleLogout()}
            >
              Logout
            </p>
          </InforBox>
        </motion.div>
      </div>
    </motion.div>
  );
};
export default Profile;
