export const getEMI = async (account_no) => {
  const formData = new URLSearchParams();
  formData.append("account_no", account_no);

  try {
    const response = await fetch("https://nagnathfinance.in/API/emi.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching user data:", error.message);
  }
};

export const getLoanData = async (account_no) => {
  const formData = new URLSearchParams();
  formData.append("account_no", account_no);
  try {
    const response = await fetch("https://nagnathfinance.in/API/loan.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching user data:", error.message);
  }
}