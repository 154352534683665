import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import InforBox from "../../componants/UI/InforBox";
import Infodisplay from "../../componants/UI/Infodisplay";
import PageTitle from "../../componants/UI/PageTitle";
import Infodate from "../../componants/UI/Infodate";
import UserContext from "../../context/UserContext";
import { getEMI, getLoanData } from "./context";

const Loan = () => {
  const { user } = useContext(UserContext);
  const [pendingEMI, setPendingEMI] = useState([]);
  const [paidEMI, setPaidEMI] = useState([]);
  const [loanData, setLoanData] = useState();

  const getData = async () => {
    const res = await getEMI(user.Account_No);
    const EMI = res.data;
    if (res) {
      const paidInstallments = [];
      const pendingInstallments = [];

      if (EMI) {
        EMI.forEach((installment) => {
          if (installment.installment_status === "paid") {
            paidInstallments.push(installment);
          } else if (installment.installment_status === "pending") {
            pendingInstallments.push(installment);
          }
        });
        setPendingEMI(pendingInstallments);
        setPaidEMI(paidInstallments);
      }
    }
  };

  const getLoanDetails = async () => {
    const res = await getLoanData(user.Account_No);
    const data = res.data;
    setLoanData(data);
  };

  useEffect(() => {
    getData();
    getLoanDetails();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-[#2B2D42] mx-auto lg:w-[360px] h-screen w-screen flex flex-col"
    >
      <PageTitle title={"Active Loan Info"} nav="/home" />
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="w-full overflow-auto py-4 pb-4 h-full rounded-t-[25px] bg-[#EDF2F4]"
      >
        {!loanData ? (
          <p className="flex justify-center items-center">No Loan Details Found.</p>
        ) : (
          <>
            <InforBox title="Basic Information">
              <Infodisplay
                col_name="Start Date"
                col_val={loanData[0].Sanction_date}
              />
              <Infodisplay
                col_name="Total Due"
                col_val={loanData[0].Loan_amt_to_get_return}
              />
              <Infodisplay
                col_name="Minimum Due"
                col_val={loanData[0].installment_amount}
              />
            </InforBox>

            <InforBox title="Upcoming EMI">
              {pendingEMI.map((installment, idx) => {
                return (
                  <Infodate
                    key={idx}
                    date={installment.installment_due_date}
                    inst_amt={installment.installment_amount}
                  />
                );
              })}
            </InforBox>

            <InforBox title="Paid EMI">
              {paidEMI.length > 0 ? (
                <>
                  {paidEMI.map((installment, idx) => {
                    <Infodate
                      key={idx}
                      date={installment.installment_date}
                      inst_amt={installment.installment_amount}
                    />;
                  })}
                </>
              ) : (
                <p className="font-bold flex justify-center items-center">
                  No Paid EMI's
                </p>
              )}
            </InforBox>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Loan;
