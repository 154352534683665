import React from "react";

import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const PageTitle = ({ title, nav }) => {
  const navigate = useNavigate();
  return (
    <div className="flex h-[70px]  text-white px-4 justify-between ">
      <div
        className="cursor-pointer flex items-center justify-start text-[24px]"
        onClick={() => {
          navigate(nav);
        }}
      >
        <IoIosArrowBack />
      </div>
      <div className="flex items-center justify-center text-[20px] font-sans font-[600]">
        {title}
      </div>
      <div className="flex items-center justify-end"></div>
    </div>
  );
};

export default PageTitle;
