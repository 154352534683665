import React from "react";
import { motion } from "framer-motion";
import InforBox from "../../componants/UI/InforBox";
import PageTitle from "../../componants/UI/PageTitle";
import Infodisplay from "../../componants/UI/Infodisplay";

const FixedDeposite = () => {
  // Animation for the container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.6 },
    },
  };

  // Animation for each InforBox
  const inforBoxVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5, duration: 0.6 },
    },
  };

  return (
    <motion.div
      className="bg-[#2B2D42] mx-auto lg:w-[360px] h-screen w-screen flex flex-col"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <PageTitle title="Fixed Deposits" nav="/home" />
      <div className="w-full overflow-auto py-4 pb-4 h-full rounded-t-[25px] bg-[#EDF2F4]">
        <motion.div variants={inforBoxVariants}>
          <InforBox title="">
            <Infodisplay col_name="FD Number" col_val="554454575458" />
            <Infodisplay col_name="Start Date" col_val="14 Feb 2024" />
            <Infodisplay col_name="Maturity Date" col_val="14 Feb 2024" />
            <Infodisplay col_name="Principle Amount" col_val="Rs. 99,999" />
            <Infodisplay col_name="Return Amount" col_val="Rs. 1,00,000" />
            <Infodisplay col_name="Today's Value" col_val="Rs. 1,09,018" />
            <Infodisplay col_name="Maturity Date" col_val="14 Feb 2024" />
          </InforBox>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FixedDeposite;
